import table from '@/mixin/table'
import reloadData from '@/mixin/reloadData'
import modalComponent from '@/mixin/modalComponent'
import CDivider from '@/components/CDivider'
import { isEqual } from '@/utils/helper'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'

import { UNSAVED_TEXT } from './constants'

export default {
  mixins: [table, reloadData, modalComponent],
  inject: ['toast'],
  props: {},
  components: {
    CDivider,
    ConfirmDialog,
  },
  data() {
    return {
      key: 0,
      workflows: [],
      noDelete: null,
      loading: false,
      loadingTable: false,
      origin: {},
      errors: {},
      defaultForm: {},
    }
  },
  computed: {
    disabled() {
      return isEqual(this.form, this.origin)
    },
  },
  watch: {
    workflows: function (val) {
      val.forEach((el) => {
        if (el.name === this.form.name) this.setNew(el)
      })
    },
  },
  mounted() {
    this.$nextTick(() => this.setNew())
  },
  methods: {
    setNew(workflow = undefined) {
      this.$refs.form.clearErrors()
      workflow = workflow ? workflow : this.defaultForm
      workflow = this.mapData(workflow)
      this.form = this.$deepClone(workflow)
      setTimeout(() => (this.origin = this.$deepClone(this.form)), 50)
    },
    async handleNew() {
      if (!isEqual(this.form, this.origin)) {
        await this.$refs.confirmDialog
          .confirm({
            text: UNSAVED_TEXT,
            confirmText: 'Yes, Leave',
            cancelText: 'Cancel',
            reverse: true,
          })
          .then((res) => {
            if (res) this.setNew()
          })
      } else this.setNew()
    },
    async handleCancel() {
      if (!isEqual(this.form, this.origin)) {
        await this.$refs.confirmDialog
          .confirm({
            text: UNSAVED_TEXT,
            confirmText: 'Yes, Leave',
            cancelText: 'Cancel',
            reverse: true,
          })
          .then((res) => {
            if (res) this.form = this.$deepClone(this.origin)
          })
      } else {
        this.form = this.$deepClone(this.origin)
      }

      this.$refs.form.clearErrorsRef()
    },
    async checkCanEdit(id) {
      if (!isEqual(this.form, this.origin)) {
        await this.$refs.confirmDialog
          .confirm({
            text: UNSAVED_TEXT,
            confirmText: 'Yes, Leave',
            cancelText: 'Cancel',
            reverse: true,
          })
          .then((res) => {
            if (res) this.handleEdit(id)
          })
      } else {
        this.handleEdit(id)
      }
    },
    deleteId(id) {
      let name = () => {
        let item = this.workflows.find((i) => i.id === id)
        return item ? item.name : ''
      }
      this.$modal
        .open({
          close: 'Cancel',
          success: 'Delete',
          // label: 'Delete Workflow',
          size: 'lg',
          sublabel: !this.noDelete
            ? `Are you sure you want to delete workflow <strong>${name()}</strong>?`
            : `
              <p class="mb-0">${this.noDelete.message}</p>
              <ul style="list-style-type: none; padding: 0">
              ${this.noDelete.projects
                .map(
                  (item) => `
                <li style="color: #005D9D">
                  <a href="/projects/${item.id}/dashboard"" target="_blank">${item.name}</router-link>
                </li>
              `,
                )
                .join('')}
              </ul>
            `,
        })
        .then(() => {
          this.deleteId(id)
          this.deleteWorkflow(id)
        })
        .catch(() => (this.noDelete = null))
    },
  },
}
