<template>
  <CModal
    :key="key"
    class="ConditionModal"
    size="xl"
    :unmount-on-close="true"
    :visible="show"
    backdrop="static"
    @close="handleCancel"
    @show="handleOpen"
  >
    <CModalBody class="m-4 mb-0">
      <ConfirmDialog ref="confirmDialog" />
      <CRow class="text-center mt-3 mb-4">
        <CCol>
          <h1>Add conditions</h1>
          <span class="description">
            Name this condition. Select the type, Add an amount and set the collaborators affected by the requirement.
          </span>

          <div class="divider m-auto mt-3"></div>
        </CCol>
      </CRow>

      <CRow :xs="{ cols: 1, gutter: 3 }">
        <CCol>
          <CNInput v-model="form.condition_name" variant="ghost" label="Condition Name" required :error="errors.condition_name" :invalid="!!errors.condition_name" @blur="handleBlurCondName" />
        </CCol>
        <CCol>
          <CCol class="my-3">Scope of work value is...</CCol>
          <CNSelect v-model="form.condition_operator" :options="conditions" label="Select type of condition" :error="errors.condition_operator" :invalid="!!errors.condition_operator" @change="handleChangeCondition" />
        </CCol>
        <CCol>
          <template v-if="form.condition_operator === 'in_range'">
            <CNInput v-model="amount" mask="money" :mask-money="money" label="Enter minimum project amount (USD)" class="mb-3" :error="errors.condition_value" :invalid="!!errors.condition_value" @blur="validateField('condition_value')" />
            <CNInput v-model="amountMax" mask="money" :mask-money="money" label="Enter maximum project amount (USD)" :error="errors.condition_value_2" :invalid="!!errors.condition_value_2" @blur="validateField('condition_value_2')" />
          </template>
          <CNInput v-else v-model="amount" mask="money" :mask-money="money" label="Enter project amount (USD)" :error="errors.condition_value" :invalid="!!errors.condition_value" @blur="validateField('condition_value')" />
        </CCol>
        <CCol>
          <CCol class="mb-3">Specify to whom the condition will apply:</CCol>
          <CNSelect v-model="form.position_id" :options="options" label="Select job title" :error="errors.position_id" :invalid="!!errors.position_id" @change="validateField('position_id')" />
        </CCol>
      </CRow>

    </CModalBody>
    <CModalFooter class="mb-4">
      <CRow :xs="{ gutter: 3, col: 1 }" class="w-100 mx-4 mt-0 d-flex flex-column-reverse flex-sm-row justify-content-center">
        <CCol class="col-12 col-sm-6 text-end">
          <CButton class="btnControl" variant="outline" color="primary" @click="handleCancel">Cancel</CButton>
        </CCol>
        <CCol class="col-12 col-sm-6">
          <CButton class="btnControl" color="primary" @click="handleSave">Save</CButton>
        </CCol>
      </CRow>
    </CModalFooter>

  </CModal>
</template>

<script>
import CNInput from '@/components/ui/CNInput/CNInput'
import CNSelect from '@/components/ui/CNSelect/CNSelect'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import { equalObjectsByKeys } from '@/utils/helper'
import { DEF_ITEM, UNSAVED_TEXT } from '../constants'

export default {
  name: 'ConditionModal',
  components: {
    CNInput,
    CNSelect,
    ConfirmDialog,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: {},
    },
    jobTitles: {
      type: Array,
      default: [],
    },
    checkedTitles: {
      type: Array,
      default: [],
    },
    conditionName: {
      type: Array,
      default: [],
    },
  },
  emits: ['close', 'save'],
  data() {
    return {
      key: 0,
      backdrop: 'static', // true
      conditions: [
        { value: 'equals', name: 'Equal to' },
        { value: 'greater_than', name: 'Greater than' },
        { value: 'in_range', name: 'Within the range' },
      ],
      origin: {},
      form: {
        position_id: null,
        mandatory: true,
        has_condition: true,
        condition_name: '',
        condition_operator: 'greater_than',
        condition_value: 0,
        condition_value_2: 0,
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: ' (USD)',
        precision: 2,
      },
      validator: {},
      errors: {},
    }
  },
  computed: {
    amount: {
      get() {
        return this.form.condition_value
      },
      set(newValue) {
        this.form.condition_value = this.modifyAmount(newValue)
        this.setRules()
      },
    },
    amountMax: {
      get() {
        return this.form.condition_value_2
      },
      set(newValue) {
        this.form.condition_value_2 = this.modifyAmount(newValue)
        this.setRules()
      },
    },
    options() {
      let arr = this.checkedTitles.filter((item) => item !== this.form.position_id)
      return this.jobTitles.filter((el) => !arr.includes(el.value))
    },
  },
  mounted() {},
  methods: {
    modifyAmount(val) {
      return val.replace(' (USD)', '').replaceAll(',', '')
    },
    handleChangeCondition() {
      this.$nextTick(() => {
        this.form.condition_value_2 = 0
        this.validateField('condition_operator')
        this.setRules()
      })
    },
    setRules() {
      this.$nextTick(() => {
        let obj = {
          condition_name: [
            rules.required,
            rules.unique(this.conditionName.filter(el => el !== this.form.condition_name)),
            rules.strMax(255)
          ],
          condition_operator: [rules.required],
          condition_value: [rules.required, rules.numMin(0), rules.numMax(1000000000000)],
          position_id: [rules.required],
        }

        if (this.form.condition_operator === 'in_range') {
          let conditionValue2 = Number(this.form.condition_value_2) ? this.form.condition_value_2 : 1000000000000
          obj.condition_value = [rules.required, rules.numMin(0), rules.numMax(conditionValue2)]
          obj.condition_value_2 = [rules.required, rules.numMin(this.form.condition_value), rules.numMax(1000000000000)]
        }

        this.validator = validator(obj)
      })
    },
    handleBlurCondName() {
      this.$nextTick(() => {
        this.form.condition_name = this.form.condition_name.trim()
        this.errors['condition_name'] = this.validator.validate('condition_name', this.form.condition_name)
      })
    },
    validateField(field) {
      this.$nextTick(() => {
        this.errors[field] = this.validator.validate(field, this.form[field])
      })
    },
    handleOpen() {
      this.$nextTick(() => {
        if (this.item.index !== undefined) {
          this.origin = this.$deepClone(this.item)
          this.form = this.$deepClone(this.item)
        } else {
          this.origin = this.$deepClone(this.form)
        }
        this.setRules()
        this.validator.forgetAll()
      })
    },
    handleSave() {
      const validationResult = this.validator.validateAll(this.form)
      if (!validationResult.hasErrors) {
        this.form.has_condition = true
        this.$emit('save', this.$deepClone(this.form))
        this.handleClear()
      } else {
        this.errors = validationResult.validationErrors
      }
    },
    async handleCancel() {
      if (!equalObjectsByKeys(this.form, this.origin)) {
        await this.$refs.confirmDialog
          .confirm({
            text: UNSAVED_TEXT,
            confirmText: 'Yes, Leave',
            cancelText: 'Cancel',
            reverse: true,
          })
          .then((res) => {
            if (res) this.handleClear()
          })
      } else {
        this.handleClear()
      }
    },
    handleClear() {
      this.form = this.$deepClone(DEF_ITEM)
      this.validator.forgetAll()
      this.errors = {}
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">

.ConditionModal + .modal-backdrop{
  margin-top: 0 !important;
}

.ConditionModal {

  h1 {
    //font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #1c262f;
  }

  .description {
    //font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.002em;
    color: #677a89;
  }

  .modal-dialog {
    max-width: 560px !important;
  }

  .divider {
    width: 120px;
    border: 2px solid #0081c2;
  }

  .btnControl {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .btnControl {
    max-width: 200px;
  }
}
</style>
